@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital@1&family=Sono&display=swap');

html {
  background: url(images/bg.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

nav {
  display: flex;
  justify-content: flex-end;
  height: 60px;
}

.nav-right > ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-right > ul > li {
  display: inline;
}

.nav-item {
  height: 60px;
  line-height: 60px;
  padding: 18px 22px;
  text-align: right;
  font-family: 'Sono', sans-serif;
}

.nav-item, .nav-item:visited, .nav-item:active {
  text-decoration: none;
  color: black;
}

.header-text {
  text-align: center;
  font-family: 'Playfair Display', serif;
  font-style: italic;
  padding-bottom: 20px;
  margin: 20px;
}

.body-text {
  padding-left: 10%;
  padding-right: 10%;
  text-align: center;
  font-family: 'Sono', sans-serif;
}

.generate-button {
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 40px;
  display: block;
  padding: 0.3em 1em;
  text-decoration: none;
  color: black;
  border: solid 1px black;
  border-radius: 3px;
  transition: .4s;
  background-color: transparent;
  font-family: 'Sono', sans-serif;
  font-size: 1.2em;
}

.generate-button:hover {
  background-color: black;
  color: white;
}

